<template>
    <div class="widget__item widget__articles dashboard__block">
        <div class="articles__header">
            <h2 class="h2 articles__title">Статьи</h2>
            <ul class="articles__tabs">
                <li
                    class="articles__tab"
                    :class="{'articles__tab--active':isFavourites, 'mr-20': current_user.is_admin}"
                    @click="isFavourites = true"
                >
                    Избранные <span class="articles__tab-count">{{favourites.count}}</span>
                </li>
                <li
                    class="articles__tab"
                    :class="{'articles__tab--active':!isFavourites}"
                    @click="isFavourites = false"
                    v-if="current_user.is_admin"
                >
                    Устаревшие <span class="articles__tab-count">{{old.count}}</span>
                </li>
            </ul>
        </div>
        <ul class="articles__list" v-if="currentArticles.count > 0">
            <li
                v-if="currentArticles.list.length"
                class="articles__item"
                :class="{
                    'articles__item--favourite':isFavourites,
                    'articles__item--old':!isFavourites
                }"
                v-for="article in currentArticles.list"
            >
                <router-link :to="article.link" class="articles__link" :title="article.title">
                    <span class="articles__link-icon"/>
                    <span class="articles__link-text">{{article.title}}</span>
                </router-link>
            </li>
        </ul>
        <div
            class="articles__empty"
            v-if="currentArticles.count === 0"
        >
            <h3 class="articles__empty-title">Нет {{isFavourites ? 'избранных' : 'устаревших' }} статей</h3>
            <router-link to="/knowledge/" class="articles__empty-link">Перейти в базу знаний</router-link>
        </div>

        <router-link :to="currentHref" v-if="currentArticles.count > 4" class="articles__more">
            Ещё
            <span class="articles__more-count">{{currentArticles.count - 4}}</span>
        </router-link>
    </div>
</template>

<script>
    import {mapState} from "vuex"

    export default {
        name: "Articles",
        data() {
            return {
                isFavourites: true
            }
        },
        computed: {
            currentArticles: function () {
                return this.isFavourites ? this.favourites : this.old;
            },
            currentHref: function () {
                return  this.isFavourites ? '/knowledge/favourites' : '/knowledge/old'
            },
            ...mapState('wrapper_materials', [
                'favourites', 'old'
            ]),
            ...mapState('default_data', [
              'current_user'
            ]),
        }
    }
</script>

<style lang="sass" scoped>
    @import "#sass/v-style"

    .widget
        &__articles
            display: flex
            flex-direction: column
            height: 350px

    .articles
        &__item
            display: flex
            align-items: center
            padding-bottom: 18px
            padding-top: 13px
            border-bottom: 1px solid $border-gray

            &--favourite
                .articles__link
                    &-icon
                        background-image: $star-icon
                        @include hard-size(15px,15px)
            &--old
                .articles__link
                    color: $text-darkgray
                    &:hover
                        color: $link-main
                    &-icon
                        @include hard-size(5px,5px)
                        background: #eb5757
                        border-radius: 50%

            &:last-of-type
                padding-bottom: 0
                border-bottom: none

        &__header
            display: flex
            justify-content: space-between

        &__tabs
            display: flex
            border-bottom: 1px solid $border-gray
            list-style-type: none
            padding-left: 0
            line-height: 22px
            margin-left: 10px

        &__tab
            color: $text-gray
            position: relative
            text-transform: uppercase
            font-size: 0.875rem
            font-weight: 600
            letter-spacing: 0.06em
            cursor: pointer
            white-space: nowrap
            user-select: none

            &:hover
                color: $text-darkgray

            &--active
                color: #000
                &:hover
                    color: #000

                &::before
                    content: ''
                    position: absolute
                    height: 3px
                    width: 100%
                    bottom: -1px
                    left: 0
                    background: $gold

            &-count
                font-size: 0.75rem
                color: $text-gray
                margin-left: 5px

        &__list
            list-style-type: none
            padding-left: 0
            margin-top: 30px

        &__link
            font-size: 1rem
            color: #000
            overflow: hidden
            white-space: nowrap
            text-overflow: ellipsis
            display: flex
            align-items: center
            &-icon
                margin-right: 10px
                background-repeat: no-repeat
                background-size: cover
            &-text
                overflow: hidden
                text-overflow: ellipsis
            &:hover
                color: #1568ac

        &__more
            font-size: 0.875rem
            color: $link-main
            margin-top: auto

        &__old
            .articles__link
                color: $text-gray

        &__empty
            text-align: center
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            height: 100%
            &-title
                color: $text-gray
                margin:
                    bottom: 0
                    top: auto
            &-link
                margin-top: auto

    @media (max-width: 500px)
        .articles
            &__header
                flex-direction: column
                align-items: flex-start
            &__tabs
                margin-top: 10px
            &__tab
                font-size: 0.75rem
            &__item
                padding-bottom: 10px
            &__list
                margin-top: 10px
            &__empty
                &-title
                    font-size: 1.25rem
                &-link
                    font-size: 0.875rem



</style>
